export enum RouteCompteName {
  MON_COMPTE = 'mon-compte',
  OPTIONS_AVANCEES = 'options-avancees',
  MIEUX_VOUS_CONNAITRE = 'mieux-vous-connaitre',
  CREATION_COMPTE = 'creation-compte',
  CREATION_COMPTE_NGC = 'creation-compte-ngc',
  VALIDATION_COMPTE = 'validation-compte',
  VALIDATION_AUTHENTIFICATION = 'validation-authentification',
  MOT_DE_PASSE_OUBLIE = 'mot-de-passe-oublie',
  LOGEMENT = 'logement',
  DEFIS = 'mes-actions',
  POST_CREATION_COMPTE_ETAPE_1 = 'creation-compte-etape-1',
  POST_CREATION_COMPTE_ETAPE_2 = 'creation-compte-etape-2',
  POST_CREATION_COMPTE_ETAPE_3 = 'creation-compte-etape-3',
  POST_CREATION_COMPTE_FIN = 'creation-compte-fin',
  POST_CREATION_COMPTE_DISCLAIMER = 'creation-compte-disclaimer',
}
